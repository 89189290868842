"use client";

import { type FC } from "react";
import { type Editor, BubbleMenu } from "@tiptap/react";
import { EditorActionButtonBold, EditorActionButtonBulletList, EditorActionButtonEmoji, EditorActionButtonItalic, EditorActionButtonLink, EditorActionButtonOrderedList, EditorActionButtonStrike } from "../editor-action-buttons";
interface EditorBubbleMenuProps {
  editor: Editor;
  editable?: boolean;
}
import { cn } from "~/lib/utils";
const DO_NOT_SHOW_FOR_NODE_TYPES = ["image"];
export const EditorBubbleMenu: FC<EditorBubbleMenuProps> = ({
  editor,
  editable
}) => {
  if (!editable) {
    return <></>;
  }
  const shouldNotShow = DO_NOT_SHOW_FOR_NODE_TYPES.some(nodeType => editor.isActive(nodeType));
  return <BubbleMenu editor={editor} tippyOptions={{
    duration: 100
  }} className={cn([shouldNotShow ? "opacity-0" : ""])} data-sentry-element="BubbleMenu" data-sentry-component="EditorBubbleMenu" data-sentry-source-file="editor-bubble-menu.tsx">
      <div className="bubble-menu flex flex-row items-center gap-0.5 rounded-lg border-[0.5px] bg-background shadow-sm">
        <EditorActionButtonBold editor={editor} className="rounded-lg" data-sentry-element="EditorActionButtonBold" data-sentry-source-file="editor-bubble-menu.tsx" />
        <EditorActionButtonItalic editor={editor} className="rounded-lg" data-sentry-element="EditorActionButtonItalic" data-sentry-source-file="editor-bubble-menu.tsx" />
        <EditorActionButtonStrike editor={editor} className="rounded-lg" data-sentry-element="EditorActionButtonStrike" data-sentry-source-file="editor-bubble-menu.tsx" />

        <EditorActionButtonOrderedList editor={editor} className="rounded-lg" data-sentry-element="EditorActionButtonOrderedList" data-sentry-source-file="editor-bubble-menu.tsx" />
        <EditorActionButtonBulletList editor={editor} className="rounded-lg" data-sentry-element="EditorActionButtonBulletList" data-sentry-source-file="editor-bubble-menu.tsx" />
        <EditorActionButtonLink editor={editor} className="rounded-lg" data-sentry-element="EditorActionButtonLink" data-sentry-source-file="editor-bubble-menu.tsx" />
        <EditorActionButtonEmoji editor={editor} className="rounded-lg" data-sentry-element="EditorActionButtonEmoji" data-sentry-source-file="editor-bubble-menu.tsx" />
      </div>
    </BubbleMenu>;
};