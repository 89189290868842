"use client";

import React, { type FC, useRef } from "react";
import imageCompression from "browser-image-compression";
import { type FileWithPath, useDropzone } from "react-dropzone";
import { toast } from "sonner";
import { cn } from "~/lib/utils";
export type FileWithPreview = FileWithPath & {
  preview: string;
};
const accept = {
  "image/*": []
};
const options = {
  maxSizeMB: 2,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};
interface ImageSelectProps {
  onChange: (fileList: FileWithPreview[]) => void;
  children?: React.ReactNode;
  compress?: boolean;
  className?: string;
}
export const ImageSelect: FC<ImageSelectProps> = ({
  children,
  compress,
  className,
  ...props
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [_selectedFileList, setSelectedFileList] = React.useState<FileWithPreview[]>([]);
  const handleFileAccept = React.useCallback(async (incomingFileList: FileWithPath[]) => {
    let fileList = incomingFileList;
    if (fileList.length == 0) {
      toast.error("Dieses Bild kann nicht ausgewählt werden. Bitte versuchen Sie es mit einem anderen");
      return;
    }
    if (compress) {
      const compressedFileList = await Promise.all(fileList.map(async file => {
        try {
          const compressedFile = await imageCompression(file, options);
          return Object.assign(compressedFile, {
            path: file.path
          });
        } catch (error) {
          toast.error(`Das Bild ${file.name} konnte nicht verarbeitetet werden. Bitte versuchen Sie es mit einem anderen.`);
          return;
        }
      }));
      fileList = compressedFileList.filter(file => !!file); // remove failed images
    }
    const fileWithPreviewList = fileList.map(file => {
      const fileWithPreview = Object.assign(file, {
        preview: URL.createObjectURL(file)
      });
      return fileWithPreview;
    });
    setSelectedFileList(fileWithPreviewList);
    props.onChange(fileWithPreviewList);
  }, [compress, setSelectedFileList, props]);
  const onDrop = React.useCallback((acceptedFiles: FileWithPath[]) => {
    void handleFileAccept(acceptedFiles); // Note: useDropzone expects a sync function, so lets wrap the async one
  }, [handleFileAccept]);
  const {
    getInputProps
  } = useDropzone({
    onDrop,
    accept
  });
  const {
    onChange,
    ...inputProps
  } = getInputProps();
  return <div className={cn(["relative h-full w-full", className])} onClick={() => inputFileRef.current?.click()} data-sentry-component="ImageSelect" data-sentry-source-file="image-select.tsx">
      <input {...inputProps} onChange={event => {
      if (onChange) {
        onChange(event);
        event.target.value = ""; // make sure the user can select the same file twice after each other
      }
    }} ref={inputFileRef} />
      {children}
    </div>;
};