import { FaItalic } from "react-icons/fa6";
import { type EditorCommand } from "./command-model";
export const editorCommandItalic: EditorCommand = {
  id: "italic",
  displayValue: "Kursiv",
  icon: <FaItalic className="h-2.5 w-2.5" />,
  isActive: editor => {
    return editor.isActive("italic");
  },
  run: ({
    editor,
    range
  }) => {
    if (editor.isActive("italic")) {
      editor.chain().focus().deleteRange(range).unsetMark("italic").run();
    } else {
      editor.chain().focus().deleteRange(range).setMark("italic").run();
    }
  }
};