import { FaBold } from "react-icons/fa6";
import { type EditorCommand } from "./command-model";
export const editorCommandBold: EditorCommand = {
  id: "bold",
  displayValue: "Fett",
  icon: <FaBold className="h-2.5 w-2.5" />,
  isActive: editor => {
    return editor.isActive("bold");
  },
  run: ({
    editor,
    range
  }) => {
    if (editor.isActive("bold")) {
      editor.chain().focus().deleteRange(range).unsetMark("bold").run();
    } else {
      editor.chain().focus().deleteRange(range).setMark("bold").run();
    }
  }
};