"use client";

import { type FC } from "react";
import { type Editor } from "@tiptap/react";
import { cn } from "~/lib/utils";
import { FaItalic } from "react-icons/fa6";
import { Button } from "~/components/ui/button";
interface EditorActionButtonItalicProps {
  editor: Editor;
  className?: string;
}
export const EditorActionButtonItalic: FC<EditorActionButtonItalicProps> = ({
  editor,
  className
}) => {
  return <Button variant="ghost" size="icon" title="Kursiv" onClick={() => editor.chain().focus().toggleItalic().run()} className={cn(["h-8 w-8", editor.isActive("italic") ? "is-active bg-foreground/10 hover:bg-foreground/15" : "", className])} data-sentry-element="Button" data-sentry-component="EditorActionButtonItalic" data-sentry-source-file="editor-action-button-italic.tsx">
      <FaItalic className="h-2/5 w-2/5" data-sentry-element="FaItalic" data-sentry-source-file="editor-action-button-italic.tsx" />
    </Button>;
};