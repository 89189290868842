"use client";

import { useCallback, useEffect, useRef, type FC } from "react";
import { type NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { LoadingDots } from "~/components/ui/loading-dots";
import { Button } from "~/components/ui/button";
import { HiMiniXMark } from "react-icons/hi2";
import { useEditorFileUpload } from "./editor-upload-provider";
export const EditorUploadProgressComponent: FC<NodeViewProps> = ({
  node,
  editor,
  deleteNode
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const fileId: string | null = node.attrs.fileId;
  // avoid duplicate useEffect call in dev mode
  const initialized = useRef(false);
  const {
    uploadFileWithId,
    getFile,
    removeFile
  } = useEditorFileUpload();
  const handleUploadFile = useCallback(async () => {
    if (!fileId) {
      return;
    }
    const file = getFile(fileId);
    if (!file) {
      return;
    }
    const uploadFileResult = await uploadFileWithId(fileId);
    if (!uploadFileResult) {
      // hook should handle the error

      // remove the file from the provider
      removeFile(fileId);

      // delete self from the editor
      deleteNode();
      return;
    }
    deleteNode();
    editor.commands.setImage({
      src: uploadFileResult.url
    });
  }, [fileId, editor, getFile, removeFile, uploadFileWithId, deleteNode]);
  useEffect(() => {
    // avoid duplicate call in dev mode
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    if (!fileId) {
      // something went wrong
      deleteNode();
      return;
    } else {
      void handleUploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <NodeViewWrapper className="editor-upload-progress-component" data-sentry-element="NodeViewWrapper" data-sentry-component="EditorUploadProgressComponent" data-sentry-source-file="editor-upload-progress-component.tsx">
      <div className="content group flex w-full select-none flex-row items-center justify-start gap-2 rounded-sm bg-muted px-2 py-1 text-sm">
        <div className="flex flex-row items-center gap-2">
          <LoadingDots data-sentry-element="LoadingDots" data-sentry-source-file="editor-upload-progress-component.tsx" />
          Uploading ...
        </div>
        <div className="grow"></div>
        <div className="opacity-0 transition-all group-hover:opacity-100">
          <Button size="icon" variant="ghost" onClick={() => deleteNode()} data-sentry-element="Button" data-sentry-source-file="editor-upload-progress-component.tsx">
            <HiMiniXMark data-sentry-element="HiMiniXMark" data-sentry-source-file="editor-upload-progress-component.tsx" />
          </Button>
        </div>
      </div>
    </NodeViewWrapper>;
};