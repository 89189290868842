"use client";

import { type FC } from "react";
import { type Editor } from "@tiptap/react";
import { cn } from "~/lib/utils";
import { FaListUl } from "react-icons/fa6";
import { Button } from "~/components/ui/button";
interface EditorActionButtonBulletListProps {
  editor: Editor;
  className?: string;
}
export const EditorActionButtonBulletList: FC<EditorActionButtonBulletListProps> = ({
  editor,
  className
}) => {
  return <Button variant="ghost" size="icon" title="Ungeordnete Liste" onClick={() => editor.chain().focus().toggleBulletList().run()} className={cn(["h-8 w-8", editor.isActive("bulletList") ? "is-active bg-foreground/10 hover:bg-foreground/15" : "", className])} data-sentry-element="Button" data-sentry-component="EditorActionButtonBulletList" data-sentry-source-file="editor-action-button-bullet-list.tsx">
      <FaListUl className="h-2/5 w-2/5" data-sentry-element="FaListUl" data-sentry-source-file="editor-action-button-bullet-list.tsx" />
    </Button>;
};