import { type FC } from "react";
import { type NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import "./editor-custom-node-example-component.css";
export const EditorCustomNodeExampleComponent: FC<NodeViewProps> = ({
  ...props
}) => {
  const increase = () => {
    props.updateAttributes({
      count: (props.node.attrs.count as number) + 1
    });
  };
  return <NodeViewWrapper className="editor-custom-node-example" data-sentry-element="NodeViewWrapper" data-sentry-component="EditorCustomNodeExampleComponent" data-sentry-source-file="editor-custom-node-example-component.tsx">
      <label>React Component</label>

      <div className="content">
        This <button onClick={increase}>button</button> has been clicked{" "}
        {props.node.attrs.count} times.
      </div>
    </NodeViewWrapper>;
};