"use client";

import { type Editor } from "@tiptap/react";
import { type EditorView } from "@tiptap/pm/view";
import { type Slice } from "@tiptap/pm/model";

import { toast } from "sonner";

import { getDroppedFile } from "./functions/get-dropped-file";
import { makeImageFromFile } from "./functions/make-image-from-file";
import { useEditorFileUpload } from "../editor-upload";

export const useHandleEditorImageUpload = () => {
  const { addFile } = useEditorFileUpload();

  const handleEditorImageUpload = async (
    editor: Editor,
    _view: EditorView,
    event: DragEvent,
    _slice: Slice,
    _moved: boolean,
  ) => {
    const extractedFileResult = getDroppedFile(event);
    if (extractedFileResult.error) {
      toast.error(extractedFileResult.error);
      return false;
    }

    if (!extractedFileResult.file) {
      toast.error("Datei konnte nicht geladen werden.");
      return false;
    }

    const { file } = extractedFileResult;

    // Todo: For now only images are supported
    //       Essentially, we will need to check for other allowed file types here.
    // ==========================================

    const makeImageResult = await makeImageFromFile(extractedFileResult.file);
    if (makeImageResult.error) {
      toast.error(makeImageResult.error);
      return false;
    }
    // First add the file to the EditorUploadProvider
    const fileId = addFile(file);
    // Then pass the fileId as reference to the uploadComponent
    editor
      .chain()
      .focus()
      .insertContent({
        type: "editorUploadNode",
        attrs: { fileId: fileId },
      })
      .run();
  };

  return {
    handleEditorImageUpload,
  };
};
