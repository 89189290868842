const MAX_SIZE = {
  width: 5000, // in pixel
  height: 5000, // in pixel
};

function INTERNAL__imageFromFile(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const _URL = window.URL || window.webkitURL;
    const img = new Image(); /* global Image */

    img.onload = function () {
      const img = this as HTMLImageElement;

      if (img.width > MAX_SIZE.width || img.height > MAX_SIZE.height) {
        reject(
          new Error(
            `Das Bild ist zu groß. Die maximale Bildgröße beträgt ${MAX_SIZE.width} (Breite ) mal ${MAX_SIZE.height} (Höhe) Pixel.`,
          ),
        );
      } else {
        resolve(img); // Return the image element itself
      }
    };

    img.onerror = function () {
      reject(
        new Error(
          "Das Bild konnte nicht geladen werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
        ),
      );
    };

    img.src = _URL.createObjectURL(file);
  });
}

export async function makeImageFromFile(file: File) {
  try {
    return {
      img: await INTERNAL__imageFromFile(file),
    };
  } catch (error) {
    if (error instanceof Error) {
      return {
        error: error.message,
      };
    } else {
      return {
        error: "Das Bild konnte nicht geladen werden.",
      };
    }
  }
}
