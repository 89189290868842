import { FaStrikethrough } from "react-icons/fa6";
import { type EditorCommand } from "./command-model";
export const editorCommandStrike: EditorCommand = {
  id: "strike",
  displayValue: "Durchgestrichen",
  icon: <FaStrikethrough className="h-2.5 w-2.5" />,
  isActive: editor => {
    return editor.isActive("strike");
  },
  run: ({
    editor,
    range
  }) => {
    if (editor.isActive("strike")) {
      editor.chain().focus().deleteRange(range).unsetMark("strike").run();
    } else {
      editor.chain().focus().deleteRange(range).setMark("strike").run();
    }
  }
};