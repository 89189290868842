"use client";

import { Extension, type Range } from "@tiptap/core";
import { type Editor } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { makeSuggestionConfiguration } from "./suggestions-plugin-configuration";
import { type EditorCommand } from "./commands";
type CommandProps = {
  editor: Editor;
  range: Range;
  props: EditorCommand;
};
const INTERNAL__SlashMenuExtension = Extension.create({
  name: "slashMenuExtension",
  addOptions() {
    return {
      suggestion: {
        char: "/",
        command: ({
          editor,
          range,
          props
        }: CommandProps) => {
          // runs the passed command
          const command: EditorCommand = props;
          command.run({
            editor,
            range
          });
        }
      }
    };
  },
  addProseMirrorPlugins() {
    return [
    //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Suggestion({
      editor: this.editor,
      //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ...this.options.suggestion
    })];
  }
});
interface EditorSlashMenuExtensionProps {
  commandList: EditorCommand[];
}
export const EditorSlashMenuExtension = ({
  commandList
}: EditorSlashMenuExtensionProps) => {
  return INTERNAL__SlashMenuExtension.configure({
    suggestion: makeSuggestionConfiguration({
      commandList
    })
  });
};