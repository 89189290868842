const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];

import { type FileWithPreview } from "~/components/ui/image-select";

export function getDroppedFile(event: DragEvent) {
  const file = event?.dataTransfer?.files[0]; // the dropped file

  if (!file) {
    return {
      error: "Datei konnte nicht geladen werden.",
    };
  }

  const filesize = file.size / 1024 / 1024; // get the filesize in MB

  if (filesize > 10) {
    return {
      error: "Die Datei ist zu groß. Die maximale Dateigröße beträgt 10 MB.",
    };
  }

  if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
    return {
      error:
        "Die Dateityp ist nicht erlaubt. Erlaubte Dateitypen sind: JPEG und PNG.",
    };
  }

  const fileWithPreview: FileWithPreview = Object.assign(file, {
    preview: URL.createObjectURL(file),
  });

  return {
    file: fileWithPreview,
  };
}
