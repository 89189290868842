import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { EditorCustomNodeExampleComponent } from "./editor-custom-node-example-component";
export const EditorCustomNodeExampleExtension = Node.create({
  name: "editorCustomNodeExample",
  group: "block",
  atom: true,
  addAttributes() {
    return {
      count: {
        default: 0
      }
    };
  },
  parseHTML() {
    return [{
      tag: "editor-custom-node-example"
    }];
  },
  renderHTML({
    HTMLAttributes
  }) {
    return ["editor-custom-node-example", mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(EditorCustomNodeExampleComponent);
  }
});