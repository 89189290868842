"use client";

import { type FC } from "react";
import { type Editor } from "@tiptap/react";
import { cn } from "~/lib/utils";
import { FaStrikethrough } from "react-icons/fa6";
import { Button } from "~/components/ui/button";
interface EditorActionButtonStrikeProps {
  editor: Editor;
  className?: string;
}
export const EditorActionButtonStrike: FC<EditorActionButtonStrikeProps> = ({
  editor,
  className
}) => {
  return <Button variant="ghost" size="icon" title="Durchgestrichen" onClick={() => editor.chain().focus().toggleStrike().run()} className={cn(["h-8 w-8", editor.isActive("strike") ? "is-active bg-foreground/10 hover:bg-foreground/15" : "", className])} data-sentry-element="Button" data-sentry-component="EditorActionButtonStrike" data-sentry-source-file="editor-action-button-strike.tsx">
      <FaStrikethrough className="h-2/5 w-2/5" data-sentry-element="FaStrikethrough" data-sentry-source-file="editor-action-button-strike.tsx" />
    </Button>;
};