"use client";

import { type FC } from "react";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { cn } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { StyledLink } from "~/components/ui/styled-link";
interface EditorActionButtonImageOpenProps {
  src: string;
  className?: string;
}
export const EditorActionButtonImageOpen: FC<EditorActionButtonImageOpenProps> = ({
  src,
  className
}) => {
  return <StyledLink href={src} target="_blank" style={{
    color: "unset"
  }} data-sentry-element="StyledLink" data-sentry-component="EditorActionButtonImageOpen" data-sentry-source-file="editor-action-button-image-open.tsx">
      <Button variant="ghost" size="icon" title="Bild öffnen" className={cn(["group h-8 w-8", className])} data-sentry-element="Button" data-sentry-source-file="editor-action-button-image-open.tsx">
        <HiMiniArrowTopRightOnSquare data-sentry-element="HiMiniArrowTopRightOnSquare" data-sentry-source-file="editor-action-button-image-open.tsx" />
      </Button>
    </StyledLink>;
};