"use client";

import { type FC } from "react";
import { type NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { cn } from "~/lib/utils";
import { EditorActionButtonImageOpen } from "./actions/editor-action-button-image-open";
import { EditorActionButtonImageDelete } from "./actions/editor-action-button-image-delete";
export interface EditorLinkWithTooltipComponentProps {
  count?: number;
}
export const EditorImageWithTooltipComponent: FC<NodeViewProps> = ({
  ...props
}) => {
  return <NodeViewWrapper className={cn(["editor-image-with-tooltip-component group relative rounded", props.selected ? "ring-2 ring-primary ring-offset-2" : ""])} data-sentry-element="NodeViewWrapper" data-sentry-component="EditorImageWithTooltipComponent" data-sentry-source-file="editor-image-with-tooltip-component.tsx">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img alt="Bild in Editor" {...props.HTMLAttributes}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={props.node.attrs.src} />
      <div className="absolute left-0 right-0 top-0 hidden flex-row justify-end group-hover:flex">
        <div className="flex flex-row items-center gap-0.5 rounded-md border-[0.5px] bg-black/80 text-white shadow-sm">
          <EditorActionButtonImageOpen
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        src={props.node.attrs.src} className="text-white" data-sentry-element="EditorActionButtonImageOpen" data-sentry-source-file="editor-image-with-tooltip-component.tsx" />
          <EditorActionButtonImageDelete editor={props.editor} node={props.node} getPos={props.getPos} data-sentry-element="EditorActionButtonImageDelete" data-sentry-source-file="editor-image-with-tooltip-component.tsx" />
        </div>
      </div>
    </NodeViewWrapper>;
};