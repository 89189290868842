import { ReactRenderer } from "@tiptap/react";
import { type SuggestionProps, type SuggestionOptions, type SuggestionKeyDownProps } from "@tiptap/suggestion";
import tippy, { type Instance as TippyInstance } from "tippy.js";
import { type EditorCommand } from "./commands";
import { CommandView, type CommandViewProps } from "./command-view";
interface MakeSuggestionConfigurationProps {
  commandList: EditorCommand[];
}
export function makeSuggestionConfiguration({
  commandList
}: MakeSuggestionConfigurationProps) {
  const items: SuggestionOptions["items"] = ({
    query
  }) => {
    // query is the text the user types after /
    // we don't use it as we delegate filterting to the command view
    return commandList.filter(command => command.displayValue.toLowerCase().startsWith(query.toLowerCase())).slice(0, 10);
  };
  const render: SuggestionOptions["render"] = () => {
    let component: ReactRenderer<typeof CommandView, CommandViewProps> | null = null;
    let popup: TippyInstance | null = null;
    const getReferenceClientRect = (props: SuggestionProps<EditorCommand, EditorCommand>) => {
      if (!props.clientRect) {
        return new DOMRect(0, 0, 0, 0);
      }
      const clientRect = props.clientRect();
      if (!clientRect) {
        return new DOMRect(0, 0, 0, 0);
      }
      return clientRect;
    };
    return {
      onStart: (props: SuggestionProps<EditorCommand, EditorCommand>) => {
        component = new ReactRenderer(CommandView, {
          editor: props.editor,
          props: {
            ...props,
            editor: props.editor,
            range: props.range,
            commandList: props.items,
            // props. command is a function that is called
            // when a suggestion is selected
            run: props.command,
            hide: () => {
              popup?.hide();
            }
          }
        });
        if (!props.clientRect) {
          return;
        }
        const {
          element: editorElement
        } = props.editor.options;

        // Note: we need to append it to the editor (not body = default),
        // to have it work in an active Dialog (which blocks interactions with the body)
        popup = tippy(editorElement, {
          getReferenceClientRect: () => getReferenceClientRect(props),
          appendTo: () => editorElement,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start"
        });
      },
      onUpdate: (props: SuggestionProps) => {
        component?.updateProps(props);
        if (!props.clientRect) {
          return;
        }
        popup?.setProps({
          //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          getReferenceClientRect: () => getReferenceClientRect(props)
        });
      },
      onExit: () => {
        popup?.destroy();
        component?.destroy();
      },
      onKeyDown: (props: SuggestionKeyDownProps): boolean => {
        if (props.event.key === "Escape") {
          popup?.hide();
          return true;
        }

        // return component?.ref?.onKeyDown(props);
        return false;
      }
    };
  };
  return {
    items: items,
    render: render
  };
}