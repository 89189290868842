import { FaListOl } from "react-icons/fa6";
import { type EditorCommand } from "./command-model";
export const editorCommandOrderedList: EditorCommand = {
  id: "orderedList",
  displayValue: "Geordnete Liste",
  icon: <FaListOl className="h-2.5 w-2.5" />,
  isActive: editor => {
    return editor.isActive("orderedList");
  },
  run: ({
    editor,
    range
  }) => {
    if (editor.isActive("orderedList")) {
      editor.chain().focus().toggleOrderedList().run();
    } else {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    }
  }
};