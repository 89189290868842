import { type JSONContent } from "@tiptap/core";
export function removeNodesOfType(node: JSONContent, nodeTypeToRemove: string): JSONContent | null {
  /**
   * Recursively removes nodes of the specified type from a single JSONContent object.
   *
   * @param node - A JSONContent object representing a ProseMirror node.
   * @param nodeTypeToRemove - The type of node to remove.
   * @returns - The cleaned JSONContent object, or null if the root node itself should be removed.
   */
  // If the current node is of the type to remove, return null to indicate removal
  if (node.type === nodeTypeToRemove) {
    return null;
  }

  // If the node has content, recursively clean its content
  if (node.content && Array.isArray(node.content)) {
    node.content = node.content.map(childNode => removeNodesOfType(childNode, nodeTypeToRemove)) // Apply removal recursively
    .filter((childNode): childNode is JSONContent => childNode !== null); // Filter out nulls
  }

  // Return the cleaned node
  return node;
}