import * as React from "react";
import { type Mark } from "@tiptap/pm/model";
import { cn } from "~/lib/utils";
import { HiPencil, HiTrash } from "react-icons/hi2";
import { StyledLink } from "~/components/ui/styled-link";
import { Button } from "~/components/ui/button";
import { EditorActionPopoverLink } from "../../editor-menu/editor-action-buttons/editor-action-popover-link";
import { EditorLinkTooltipPlugin, type EditorLinkTooltipProps } from "./editor-link-tooltip";
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type EditorLinkTooltipViewProps = Omit<Optional<EditorLinkTooltipProps, "pluginKey">, "element"> & {
  className?: string;
  updateDelay?: number;
};
export const EditorLinkTooltipView = (props: EditorLinkTooltipViewProps) => {
  const {
    editor,
    pluginKey = "linkActions",
    tippyOptions = {},
    updateDelay,
    shouldShow = null,
    className
  } = props;
  const [element, setElement] = React.useState<HTMLDivElement | null>(null);
  const [linkHref, setLinkHref] = React.useState<string | null>(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!element) {
      return;
    }
    if (editor.isDestroyed) {
      return;
    }
    const plugin = EditorLinkTooltipPlugin({
      updateDelay,
      editor,
      element,
      pluginKey,
      shouldShow,
      tippyOptions
    });
    editor.registerPlugin(plugin);
    return () => {
      editor.unregisterPlugin(pluginKey);
    };
    // Register plugin when the editor is ready or the element changes.
    // We don't want to consider other props changes as they are not
    // coming from a react state or ref, which makes the menu rendering
    // unstable and non-ending process.

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, element]);
  React.useEffect(() => {
    const {
      $from
    } = editor.state.selection;
    const linkMark = $from.marks().find((mark: Mark) => mark.type.name === "link");
    if (!linkMark) {
      setLinkHref(null);
      setVisible(false);
    } else {
      setLinkHref(linkMark.attrs.href as string);
      setVisible(true);
    }
    return () => {
      setLinkHref(null);
    };
  }, [editor.state.selection]);
  const handleRemoveLink = () => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
  };
  return <div ref={setElement} className={cn(["overflow-hidden border-none bg-transparent transition-all", !visible ? "h-0 w-0" : "", className])} style={{
    visibility: "hidden"
  }} data-sentry-component="EditorLinkTooltipView" data-sentry-source-file="editor-link-tooltip-view.tsx">
      <div className="flex flex-col items-center justify-start gap-2 overflow-hidden rounded-lg border-[0.5px] bg-background p-0 text-sm shadow-sm">
        <div className="flex flex-row items-center justify-start gap-3 text-sm">
          <StyledLink className="grow pl-2.5 font-semibold text-primary" href={linkHref ?? ""} target="_blank" rel="noreferrer" data-sentry-element="StyledLink" data-sentry-source-file="editor-link-tooltip-view.tsx">
            {linkHref}
          </StyledLink>
          <div className="flex flex-row items-center justify-end gap-1">
            <EditorActionPopoverLink editor={editor} className="h-8 w-8" data-sentry-element="EditorActionPopoverLink" data-sentry-source-file="editor-link-tooltip-view.tsx">
              <HiPencil className="h-3 w-3" data-sentry-element="HiPencil" data-sentry-source-file="editor-link-tooltip-view.tsx" />
            </EditorActionPopoverLink>
            <Button variant="ghost" size="icon" title="Link entfernen" onClick={handleRemoveLink} className="h-8 w-8" data-sentry-element="Button" data-sentry-source-file="editor-link-tooltip-view.tsx">
              <HiTrash className="h-3 w-3" data-sentry-element="HiTrash" data-sentry-source-file="editor-link-tooltip-view.tsx" />
            </Button>
          </div>
        </div>
      </div>
    </div>;
};