"use client";

import { type FC } from "react";
import { type Editor } from "@tiptap/react";
import { type Node } from "@tiptap/pm/model";
import { cn } from "~/lib/utils";
import { HiTrash } from "react-icons/hi2";
import { Button } from "~/components/ui/button";
interface EditorActionButtonImageDeleteProps {
  editor: Editor;
  node: Node;
  getPos: () => number;
  className?: string;
}
export const EditorActionButtonImageDelete: FC<EditorActionButtonImageDeleteProps> = ({
  editor,
  node,
  getPos,
  className
}) => {
  function handleDeleteNode() {
    const from = getPos();
    const to = from + node.nodeSize;
    editor.commands.deleteRange({
      from,
      to
    });
  }
  return <Button variant="ghost" size="icon" title="Bild löschen" onClick={() => handleDeleteNode()} className={cn(["h-8 w-8", className])} data-sentry-element="Button" data-sentry-component="EditorActionButtonImageDelete" data-sentry-source-file="editor-action-button-image-delete.tsx">
      <HiTrash className="h-2/5 w-2/5" data-sentry-element="HiTrash" data-sentry-source-file="editor-action-button-image-delete.tsx" />
    </Button>;
};