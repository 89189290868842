import { type FC, Fragment, useEffect, useRef } from "react";
import { type Editor, type Range } from "@tiptap/core";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "~/components/ui/command";
import { type EditorCommand } from "./commands";
import { NodeViewWrapper } from "@tiptap/react";
import { cn } from "~/lib/utils";
export interface CommandViewProps {
  editor: Editor;
  range: Range;
  commandList: EditorCommand[];
  run: (command: EditorCommand) => void;
  hide: () => void;
}
export const CommandView: FC<CommandViewProps> = ({
  editor,
  range,
  commandList,
  run,
  hide
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      editor.chain().focus().run();
      hide();
    }
  };
  useEffect(() => {
    // for some reason we need to defer this for one cycle
    // otherwise the focus is not set correctly
    setTimeout(() => {
      ref?.current?.focus();
    }, 1);
  }, [ref]);
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <NodeViewWrapper className="rounded-lg border bg-background shadow-sm" data-sentry-element="NodeViewWrapper" data-sentry-component="CommandView" data-sentry-source-file="command-view.tsx">
      <Command data-sentry-element="Command" data-sentry-source-file="command-view.tsx">
        <CommandInput placeholder="Tippen Sie einen Befehl ..." className="text-sm placeholder:text-sm" autoFocus={true} ref={ref} data-sentry-element="CommandInput" data-sentry-source-file="command-view.tsx" />
        <CommandList data-sentry-element="CommandList" data-sentry-source-file="command-view.tsx">
          <CommandEmpty data-sentry-element="CommandEmpty" data-sentry-source-file="command-view.tsx">Keine Ergebnisse gefunden.</CommandEmpty>
          <CommandGroup heading="Vorschläge" data-sentry-element="CommandGroup" data-sentry-source-file="command-view.tsx">
            {commandList.map((item, index) => {
            if (item.render) {
              return <Fragment key={index}>
                    {item.render({
                  editor,
                  range
                })}
                  </Fragment>;
            }
            return <CommandItem key={index} onSelect={() => {
              run(item);
            }} className={cn(["flex flex-row items-center justify-start gap-2", item.isActive(editor) ? "font-semibold" : ""])}>
                  {item.icon}
                  {item.displayValue}
                </CommandItem>;
          })}
          </CommandGroup>
        </CommandList>
      </Command>
    </NodeViewWrapper>;
};