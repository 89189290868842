"use client";

import { useCallback, useRef, useState, type FC } from "react";
import { type Editor } from "@tiptap/react";
import { cn } from "~/lib/utils";
import { HiMiniTrash } from "react-icons/hi2";
import { Popover, PopoverPortalContent, PopoverTrigger } from "~/components/ui/popover";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
interface EditorActionPopoverLinkProps {
  editor: Editor;
  className?: string;
  children?: React.ReactNode;
}
export const EditorActionPopoverLink: FC<EditorActionPopoverLinkProps> = ({
  editor,
  className,
  children
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const activeLink = editor.getAttributes("link").href as string;
  const handleSetLink = useCallback((newLink: string) => {
    // empty
    if (newLink === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({
      href: newLink
    }).run();
  }, [editor]);
  const handleRemoveLink = useCallback(() => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
  }, [editor]);
  return <>
      <div ref={ref}></div>
      <Popover open={open} onOpenChange={setOpen} data-sentry-element="Popover" data-sentry-source-file="editor-action-popover-link.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="editor-action-popover-link.tsx">
          <Button variant="ghost" size="icon" title="Fett" className={cn(["h-8 w-8", className])} onClick={() => {
          setUrl(activeLink ?? "");
        }} data-sentry-element="Button" data-sentry-source-file="editor-action-popover-link.tsx">
            {children}
          </Button>
        </PopoverTrigger>
        <PopoverPortalContent className="flex flex-col gap-1" container={ref.current ?? undefined} data-sentry-element="PopoverPortalContent" data-sentry-source-file="editor-action-popover-link.tsx">
          <div className="text-xs font-semibold">Link</div>
          <div className="item-center flex w-full flex-row items-center justify-start gap-2">
            <Input className="h-8 grow" value={url} onChange={e => setUrl(e.currentTarget.value)} placeholder="https://www.beispiel.de" data-sentry-element="Input" data-sentry-source-file="editor-action-popover-link.tsx" />
            <Button size="icon" variant="ghost" onClick={() => handleRemoveLink()} title="Link entfernen" data-sentry-element="Button" data-sentry-source-file="editor-action-popover-link.tsx">
              <HiMiniTrash className="h-4 w-4" data-sentry-element="HiMiniTrash" data-sentry-source-file="editor-action-popover-link.tsx" />
            </Button>
          </div>
          <div className="item-center flex w-full flex-row justify-end gap-1 pt-1">
            <Button size="sm" variant="ghost" onClick={() => {
            setOpen(false);
          }} title="Abbrechen" data-sentry-element="Button" data-sentry-source-file="editor-action-popover-link.tsx">
              Abbrechen
            </Button>
            <Button size="sm" variant="ghost" className="border-[0.5px] bg-[#FCFAF8]" onClick={() => handleSetLink(url)} title="Speichern" data-sentry-element="Button" data-sentry-source-file="editor-action-popover-link.tsx">
              Speichern
            </Button>
          </div>
        </PopoverPortalContent>
      </Popover>
    </>;
};