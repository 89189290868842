"use client";

import "./editor.css";
import { type FC, useMemo, useState } from "react";
import { useEditor, EditorContent, type AnyExtension } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import Gapcursor from "@tiptap/extension-gapcursor";
import Dropcursor from "@tiptap/extension-dropcursor";
import History from "@tiptap/extension-history";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import HardBreak from "@tiptap/extension-hard-break";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Link from "@tiptap/extension-link";
import { cn } from "~/lib/utils";
import { EditorBubbleMenu, EditorSlashMenuExtension, EditorLinkTooltipView, isDroppedFileEvent, useHandleEditorImageUpload, EditorUploadProgessExtension, EditorImageWithTooltipExtension, editorCommandBold, editorCommandItalic, editorCommandStrike, editorCommandBulletList, editorCommandOrderedList, editorCommandUploadAndInsertImage, EditorUploadProvider } from "./editor-extensions";
import { removeNodesOfType } from "./utils/removeNodesOfType";
import { type EditorView } from "@tiptap/pm/view";
import { type Slice } from "@tiptap/pm/model";
export interface EditorProps {
  className?: string;
  placeholder?: string;
  content?: JSON | null | undefined;
  onUpdate?: (json: JSON | null) => void;
  editable?: boolean;
  showBubbleMenu?: boolean;
  showSlashMenu?: boolean;
  linkPreview?: boolean;
  allowImageUpload?: boolean;
}
const INTERNAL__Editor: FC<EditorProps> = ({
  className,
  placeholder,
  editable = true,
  showBubbleMenu = true,
  showSlashMenu = true,
  linkPreview = true,
  allowImageUpload = true,
  ...props
}) => {
  const [busy, setBusy] = useState(false);
  const {
    handleEditorImageUpload
  } = useHandleEditorImageUpload();
  const menuConfiguration = useMemo(() => {
    const commandList = [editorCommandBold, editorCommandItalic, editorCommandStrike, editorCommandBulletList, editorCommandOrderedList];
    if (allowImageUpload) {
      commandList.push(editorCommandUploadAndInsertImage);
    }
    return commandList;
  }, [allowImageUpload]);
  const optionalExensions = useMemo(() => {
    const extensions: AnyExtension[] = [];
    if (allowImageUpload) {
      extensions.push(EditorImageWithTooltipExtension);
      extensions.push(EditorUploadProgessExtension);
    }
    if (showSlashMenu) {
      extensions.push(EditorSlashMenuExtension({
        commandList: menuConfiguration
      }));
    }
    return extensions;
  }, [allowImageUpload, showSlashMenu, menuConfiguration]);
  const editor = useEditor({
    immediatelyRender: false,
    content: props.content,
    editable: editable,
    extensions: [
    // Nodes
    Document, Paragraph, Text, BulletList, OrderedList, ListItem, HardBreak,
    // Marks
    Bold, Strike, Italic,
    // Underline,
    // Code,
    Link.configure({
      openOnClick: false,
      autolink: true,
      linkOnPaste: true
    }),
    // Extensions
    Gapcursor, Dropcursor.configure({
      class: "dropcursor"
    }), History, Placeholder.configure({
      placeholder: () => {
        return placeholder ?? "Schreiben Sie etwas oder tippen Sie '/' für Befehle...";
      }
    }),
    // Custom Extensions
    // EditorCustomNodeExampleExtension,
    ...optionalExensions],
    autofocus: true,
    editorProps: {
      attributes: {
        class: cn(["outline-none border-none"], className)
      },
      handleDrop: handleDrop
    },
    onUpdate: handleEditorContentHasUpdated
  });
  function handleDrop(view: EditorView, event: DragEvent, slice: Slice, moved: boolean) {
    if (!editor) {
      return false;
    }
    if (isDroppedFileEvent(event, moved)) {
      if (!allowImageUpload) {
        return false;
      }
      setBusy(true);
      void handleEditorImageUpload(editor, view, event, slice, moved).then(() => {
        handleEditorContentHasUpdated();
        setBusy(false);
      });
      return true; // we handled the event
    }
    return false; // we didn't handle the event
  }
  function handleEditorContentHasUpdated() {
    if (!props.onUpdate) {
      return;
    }
    if (!editor) {
      return;
    }
    if (busy) {
      return;
    }
    if (editor.isEmpty) {
      props.onUpdate(null);
      return;
    }
    const editorState = editor.getJSON();
    // make sure no pending upload is stored in the db
    const cleanedEditorState = removeNodesOfType(editorState, "editorUploadNode");
    if (cleanedEditorState == null) {
      props.onUpdate(null);
      return;
    }
    props.onUpdate(cleanedEditorState as JSON);
  }
  return <div data-sentry-component="INTERNAL__Editor" data-sentry-source-file="editor.tsx">
      {showBubbleMenu && editor && <EditorBubbleMenu editor={editor} editable={editable} />}
      {linkPreview && editor && <EditorLinkTooltipView editor={editor} />}

      <EditorContent editor={editor} data-sentry-element="EditorContent" data-sentry-source-file="editor.tsx" />
    </div>;
};
export const Editor: FC<EditorProps> = ({
  ...props
}) => {
  return <EditorUploadProvider data-sentry-element="EditorUploadProvider" data-sentry-component="Editor" data-sentry-source-file="editor.tsx">
      <INTERNAL__Editor {...props} data-sentry-element="INTERNAL__Editor" data-sentry-source-file="editor.tsx" />
    </EditorUploadProvider>;
};