import "./editor-content-view.css";
import React from "react";
import { cn } from "~/lib/utils";
import { generateHTML } from "@tiptap/html";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import HardBreak from "@tiptap/extension-hard-break";
import Link from "@tiptap/extension-link";
import Code from "@tiptap/extension-code";
import Image from "@tiptap/extension-image";
export interface EditorContentViewProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string;
  placeholder?: string;
}
const EditorContentView = React.forwardRef<HTMLDivElement, EditorContentViewProps>(({
  className,
  ...props
}, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const initialContent = React.useMemo(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(props.content);
    } catch {
      return null;
    }
  }, [props.content]);
  const content = React.useMemo(() => {
    if (!initialContent) {
      return `<p class="text-muted-foreground">${props.placeholder ?? ""}</p>`;
    }

    //eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return generateHTML(initialContent, [Document, Paragraph, Text, Bold, Strike, Italic, Underline, BulletList, OrderedList, ListItem, HardBreak, Link, Code, Image
    // other extensions …
    ]);
  }, [props.placeholder, initialContent]);
  return <div ref={ref} className={cn(["_content-block flex shrink-0 grow-0 flex-col gap-0", className])} dangerouslySetInnerHTML={{
    __html: content
  }}></div>;
});
EditorContentView.displayName = "EditorContentView";
export { EditorContentView };