"use client";

import { type FC } from "react";
import { type Editor } from "@tiptap/react";
import { FaLink } from "react-icons/fa6";
import { EditorActionPopoverLink } from "./editor-action-popover-link";
interface EditorActionButtonLinkProps {
  editor: Editor;
  className?: string;
}
export const EditorActionButtonLink: FC<EditorActionButtonLinkProps> = ({
  editor,
  className
}) => {
  return <EditorActionPopoverLink editor={editor} className={className} data-sentry-element="EditorActionPopoverLink" data-sentry-component="EditorActionButtonLink" data-sentry-source-file="editor-action-button-link.tsx">
      <FaLink className="h-2/5 w-2/5" data-sentry-element="FaLink" data-sentry-source-file="editor-action-button-link.tsx" />
    </EditorActionPopoverLink>;
};