"use client";

import { type FC } from "react";
import { type Editor } from "@tiptap/react";
import { cn } from "~/lib/utils";
import { FaListOl } from "react-icons/fa6";
import { Button } from "~/components/ui/button";
interface EditorActionButtonOrderedListProps {
  editor: Editor;
  className?: string;
}
export const EditorActionButtonOrderedList: FC<EditorActionButtonOrderedListProps> = ({
  editor,
  className
}) => {
  return <Button variant="ghost" size="icon" title="Geordnete Liste" onClick={() => editor.chain().focus().toggleOrderedList().run()} className={cn(["h-8 w-8", editor.isActive("orderedList") ? "is-active bg-foreground/10 hover:bg-foreground/15" : "", className])} data-sentry-element="Button" data-sentry-component="EditorActionButtonOrderedList" data-sentry-source-file="editor-action-button-ordered-list.tsx">
      <FaListOl className="h-2/5 w-2/5" data-sentry-element="FaListOl" data-sentry-source-file="editor-action-button-ordered-list.tsx" />
    </Button>;
};