"use client";

import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { EditorUploadProgressComponent } from "./editor-upload-progress-component";
export const EditorUploadProgessExtension = Node.create({
  name: "editorUploadNode",
  group: "block",
  atom: true,
  addAttributes() {
    return {
      fileId: null
    };
  },
  parseHTML() {
    return []; // make sure this is never saved
  },
  renderHTML({
    HTMLAttributes
  }) {
    return ["editor-upload-node", mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(EditorUploadProgressComponent);
  }
});