"use client";

import React, { type FC, type PropsWithChildren, createContext, useContext, useState } from "react";
import { usePathname } from "next/navigation";
import { createId } from "@paralleldrive/cuid2";
import { useUploadToObjectStorageAndStoreFile } from "~/lib/hooks";

// Define the file and upload types
interface FileUpload {
  file: File;
  url: string;
}
interface PendingFile {
  file: File;
  loading: boolean;
}
interface FileUploadContextType {
  addFile: (file: File) => string;
  getFile: (id: string) => File | null;
  removeFile: (id: string) => void;
  uploadFileWithId: (id: string) => Promise<FileUpload | null>;
}

// Create the context with default values
const FileUploadContext = createContext<FileUploadContextType | undefined>(undefined);

// Provider component
export const EditorUploadProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const pathname = usePathname();
  const storeAndUploadFile = useUploadToObjectStorageAndStoreFile();
  const [pendingFiles, setPendingFiles] = useState<Record<string, PendingFile>>({});

  // Add a file to the pendingFiles dictionary and generate a random cuid2 ID for it
  const addFile = (file: File): string => {
    const id = createId();
    setPendingFiles(prev => ({
      ...prev,
      [id]: {
        file,
        loading: false
      }
    }));
    return id; // Return the ID for further operations
  };
  const getFile = (id: string) => {
    const pendingFile = pendingFiles[id];
    if (!pendingFile) {
      return null; // Return null if no file with the given ID exists
    }
    return pendingFile.file; // Return the ID for further operations
  };
  const removeFile = (id: string) => {
    setPendingFiles(prev => {
      const updatedFiles = {
        ...prev
      };
      delete updatedFiles[id]; // Remove the file with the given ID
      return updatedFiles;
    });
  };

  // Simulate file upload progress and update the progress of the file in pendingFiles
  const uploadFileWithId = async (id: string): Promise<FileUpload | null> => {
    const pendingFile = pendingFiles[id];
    if (!pendingFile) {
      return null; // Return null if no file with the given ID exists
    }
    if (pendingFile.loading) {
      return null;
    }
    setPendingFiles(prev => ({
      ...prev,
      [id]: {
        file: pendingFile.file,
        loading: true
      }
    }));
    const storeAndUploadFileResult = await storeAndUploadFile.mutateAsync({
      file: pendingFile.file,
      metadata: {
        ownerId: pathname ?? "",
        ownerType: "text-editor",
        makePublic: true
      }
    });
    if (!storeAndUploadFileResult?.result) {
      // error handling is done by hook
      return null;
    }
    return {
      file: pendingFile.file,
      url: storeAndUploadFileResult.result.publicUrl
    };
  };
  return <FileUploadContext.Provider value={{
    addFile,
    getFile,
    removeFile,
    uploadFileWithId
  }} data-sentry-element="unknown" data-sentry-component="EditorUploadProvider" data-sentry-source-file="editor-upload-provider.tsx">
      {children}
    </FileUploadContext.Provider>;
};

// Custom hook to use FileUploadContext
export const useEditorFileUpload = (): FileUploadContextType => {
  const context = useContext(FileUploadContext);
  if (context === undefined) {
    throw new Error("useFileUpload must be used within a EditorUploadProvider");
  }
  return context;
};