import { FaListUl } from "react-icons/fa6";
import { type EditorCommand } from "./command-model";
export const editorCommandBulletList: EditorCommand = {
  id: "bulletList",
  displayValue: "Ungeordnete Liste",
  icon: <FaListUl className="h-2.5 w-2.5" />,
  isActive: editor => {
    return editor.isActive("bulletList");
  },
  run: ({
    editor,
    range
  }) => {
    if (editor.isActive("bulletList")) {
      editor.chain().focus().toggleBulletList().run();
    } else {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    }
  }
};